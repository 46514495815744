import { render, staticRenderFns } from "./SpecTab.vue?vue&type=template&id=500e79af&scoped=true&"
import script from "./SpecTab.vue?vue&type=script&lang=ts&"
export * from "./SpecTab.vue?vue&type=script&lang=ts&"
import style0 from "./SpecTab.vue?vue&type=style&index=0&id=500e79af&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "500e79af",
  null
  
)

export default component.exports